import axios from 'axios'
import { newErrorAlert } from '@actions/alertActions'
import Storage from './storage'
import Store from '../store'

function getAuthorizationToken() {
  const TOKEN = Storage.get('auth_token')
  return TOKEN
}

axios.interceptors.request.use(config => {
  config.headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: getAuthorizationToken(),
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    i18nextLng: Storage.get('i18nextLng') || 'ua',
  }
  return config
})

axios.interceptors.response.use(
  res => res,
  error => {
    const {
      status,
      statusText,
    } = error.response
    const { url } = error.config

    if (status >= 400 && status < 500) {
      switch (status) {
        case 400:
          return 'Неправильний запит'
        case 401:
          if (url === '/users/sign_in' || url === '/teachers/sign_in') {
            Store.dispatch(newErrorAlert('Невірна електронна пошта або пароль!'))

            return 'Невірна електронна пошта або пароль!'
          }

          Store.dispatch(newErrorAlert('Не авторизований'))

          return 'Не авторизований'
        case 402:
          return 'Необхідна оплата'
        case 403:
          return 'Заборонено'
        case 404:
          return 'Не знайдено'
        case 405:
          return 'Метод не підтримується'
        case 406:
          return 'Неприйнятно'
        case 407:
          return 'Необхідна аутентифікація проксі'
        case 408:
          return 'Минув час очікування'
        case 409:
          if (url === '/users') {
            return Promise.reject(error)
          }

          return 'Конфлікт'
        case 412:
          return 'Попередня умова помилкова'
        case 413:
          return 'Корисне навантаження занадто велике'
        case 414:
          return 'URI занадто довгий'
        case 415:
          return 'Непідтримуваний тип даних'
        case 417:
          return 'Очікування не вдалося'
        case 418:
          return 'Я - чайник'
        case 422:
          if (/^\/api\/v1\/(users|teachers)\/\d+\/update_password$/.test(url)) {
            Store.dispatch(newErrorAlert('Невірний поточний пароль!'))

            return 'Невірний поточний пароль!'
          }

          return 'Необроблюваний екземпляр'
        case 423:
          return 'Заблоковано'
        case 424:
          return 'Невиконана залежність'
        case 426:
          return 'Необхідне оновлення'
        case 428:
          return 'Необхідна попередня умова'
        case 429:
          return 'Занадто багато запитів'
        case 431:
          return 'Поля заголовка запиту занадто великі'
        case 449:
          return 'Повторити з'
        case 451:
          return 'Недоступно з юридичних причин'
        case 499:
          return 'Клієнт закрив з\'єднання'
        default:
          return statusText
      }
    } else if (status >= 500) {
      switch (status) {
        case 500:
          Store.dispatch(newErrorAlert('Внутрішня помилка сервера'))

          return 'Внутрішня помилка сервера'
        case 501:
          return 'Не реалізовано'
        case 502:
          return 'Неправильний шлюз'
        case 503:
          return 'Сервіс недоступний'
        case 504:
          return 'Шлюз не відповідає'
        case 505:
          return 'Версія HTTP не підтримується'
        case 507:
          return 'Переповнення сховища'
        case 508:
          return 'Виявлено нескінченне перенаправлення'
        case 509:
          return 'Вичерпана пропускна здатність каналу'
        case 510:
          return 'Не розширено'
        case 511:
          return 'Потрібна мережева аутентифікація'
        case 520:
          return 'Невідома помилка'
        case 521:
          return 'Веб-сервер не працює'
        case 522:
          return 'З\'єднання не відповідає'
        case 523:
          return 'Джерело недоступне'
        case 524:
          return 'Час очікування минув'
        case 526:
          return 'Недійсний сертифікат SSL'
        default:
          return statusText
      }
    } else {
      return null
    }
  },
)

/* get user role */
export async function getRoleHelper() {
  const res = await axios.get('/api/v1/actual_user')
  return res.data.role
}

/* get user results by lesson */
export async function fetchResult(id, isHomework = false) {
  let url = `/api/v1/live_lessons/${id}/results`
  if (isHomework) {
    url += '?homework=true'
  }
  const res = await axios.get(url)
  return res.data
}

export function sendComment(liveLessonId, exerciseId, comment) {
  const data = {
    exercise_id: exerciseId,
    exercise_comment: comment,
  }
  axios.post(`/api/v1/live_lessons/${liveLessonId}/add_comment`, data)
}

export function removeComment(liveLessonId, commentId, exerciseId) {
  axios.post(`/api/v1/live_lessons/${liveLessonId}/remove_comment/${commentId}`, { exerciseId })
}

export function logoutUser() {
  axios.delete('/users/sign_out')
}

export function unsubscribe(liveLessonId) {
  axios.post(`/api/v1/live_lessons/${liveLessonId}/unsubscribe`, {})
}

export function attachExercise(liveLessonId, exerciseId) {
  axios.post(`/api/v1/live_lessons/${liveLessonId}/attach_exercise`, { exercise_id: exerciseId })
}

export function dettachExercise(liveLessonId, exerciseId) {
  axios.post(`/api/v1/live_lessons/${liveLessonId}/detach_exercise`, { exercise_id: exerciseId })
}

/* send answer from user to teacher */
export function sendAnswers(data, id) {
  axios.post(`/api/v1/live_lessons/${id}/answer.json`, data)
}

export function sendMediaAnswers(data, id) {
  axios.post(`/api/v1/live_lessons/${id}/media_answer.json`, data)
}

/* save user answers */
export function saveAnswers(data, id) {
  axios.post(`/api/v1/live_lessons/${id}/save_answer.json`, data)
}

export function addZoomUrl(url, id) {
  axios.post(`/api/v1/live_lessons/${id}/zoom`, { url })
}

/* get all teacher students */
export async function getStudents() {
  const res = await axios.get('/api/v1/teacher_profile/teacher_users')
  return res.data.users
}

/* get all teacher camps */
export async function getTeacherCamps() {
  const res = await axios.get('/api/v1/teacher_profile/teacher_camps')
  return res.data
}

export async function getTeacherCampStudents(id) {
  const res = await axios.get(`/api/v1/students_camps/${id}`)
  return res.data
}

/* get user lesson list */
export async function getCourseLessons(userId, courseId) {
  const res = await axios.get(`/api/v1/users/${userId}/courses/${courseId}/lessons`)
  return res.data
}

/* get user lesson homework */
export async function getCourseHomeworks(userId, courseId) {
  const res = await axios.get(`/api/v1/users/${userId}/courses/${courseId}/homeworks`)
  return res.data
}

export async function studentInfo(id) {
  const res = await axios.get(`/api/v1/users/${id}`)
  return res.data
}

export async function teacherInfo(id) {
  const res = await axios.get(`/api/v1/teachers/${id}`)
  return res.data
}

export async function startDigitalLesson(liveLessonId, status) {
  const res = await axios.patch(`/api/v1/live_lessons/${liveLessonId}`, {
    live_lesson: { aasm_state: status },
    start_digital_lesson: true,
  })
  return res.status
}

export async function updateAvatar(user, role, data) {
  const { id } = user
  const res = await axios.patch(`/api/v1/${role}s/${id}/update_avatar`, data)
  return res.data
}

export async function finishLesson(liveLessonId) {
  const res = await axios.post(`/api/v1/live_lessons/${liveLessonId}/close_room`, {})
  return res.status
}

export async function failLesson(liveLessonId) {
  const res = await axios.post(`/api/v1/live_lessons/${liveLessonId}/failed_lesson`, {})
  return res.status
}

export async function updateLiveLessonField(liveLessonId, field, value, homework_unit_id) {
  if (homework_unit_id) {
    const res = await axios.patch(`/api/v1/live_lessons/${liveLessonId}?homework_unit_id=${homework_unit_id}`,
      {
        [`${field}`]: value,
        has_completed_homework: true,
      })
    return res.status
  }
  const res = await axios.patch(`/api/v1/live_lessons/${liveLessonId}`,
    {
      [`${field}`]: value,
    })
  return res.status
}

export async function getLanguages() {
  const res = await axios.get('/api/v1/languages')
  return res.data
}

export async function getRecomendCourses(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/course`)
  return res.data
}

export async function getUserGroupCourses(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/group_courses`)
  return res.data
}

export async function getActiveCourses(userId, courseId) {
  const res = await axios.get(`/api/v1/users/${userId}/course?course_id=${courseId}`)
  return res.data
}

export async function getCourses(langId) {
  const res = await axios.get(`/api/v1/languages/${langId}`)
  return res.data
}

export async function getTeacherCourses() {
  const res = await axios.get('/api/v1/courses')
  return res.data
}

export async function getBoughtCourses(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/bought_courses`)
  return res.data
}

export async function getUserCarrentHomeworks(userId) {
  const res = await axios.get(`/api/v1/teacher_profile/user_homeworks/${userId}`)
  return res.data
}

export async function getUserCurrentCampHomeworks(userId, groupId) {
  const res = await axios.get(`/api/v1/teacher_profile/user_camp_homeworks/${userId}/?students_camp_id=${groupId}`)
  return res.data
}

export async function getUserCarrentLessons(userId) {
  const res = await axios.get(`/api/v1/teacher_profile/user_live_lessons/${userId}`)
  return res.data
}

export async function signOut() {
  const res = await axios.delete('/users/sign_out')
  return res.status
}

export async function purchaseCourse(id) {
  const res = await axios.get(`/api/v1/courses/${id}`)
  return res.data
}

export async function userPurchaseCourse(userID, courseID) {
  const res = await axios.get(`/api/v1/users/${userID}/course?course_id=${courseID}`)
  return res.data
}

export async function userGifts(id) {
  const res = await axios.get(`/api/v1/users/${id}/gift_packages`)
  return res.data
}

export async function teacherCourseLessons(id) {
  const res = await axios.get(`/api/v1/courses/${id}/lesson_contents`)
  return res.data
}

export async function teacherCourseHomework(id) {
  const res = await axios.get(`/api/v1/courses/${id}/homeworks`)
  return res.data
}

export async function buyPackage(data) {
  const res = await axios.post('/api/v1/charges', data)
  return res
}

export async function getMateraials(id) {
  const res = await axios.get(`/api/v1/courses/${id}/book_materials`)
  return res.data
}

export async function getLiveLessonMaterials(liveLessonId) {
  const res = await axios.get(`/api/v1/live_lessons/${liveLessonId}/materials`)
  return res.data
}

export async function deleteLessonFile(materailsId) {
  const res = await axios.delete(`/api/v1/additional_materials/${materailsId}`)
  return res
}

export async function loadHomeworToLesson(liveLessonId, file, config) {
  const formData = new FormData()
  formData.append('live_lesson_homework', file, file.name)
  const res = await axios.post(
    `/api/v1/live_lessons/${liveLessonId}/add_users_homework`,
    formData,
    config,
  )
  return res.data
}

export async function removeHomeworFromLesson(liveLessonId, fileId) {
  const res = await axios.post(
    `/api/v1/live_lessons/${liveLessonId}/remove_users_homework`,
    { homework_task_id: fileId },
  )
  return res.status
}

export async function paymentHistory(id) {
  const res = await axios.get(`/api/v1/users/${id}/bought_packages`)
  return res.data
}

export async function getUserNotifications(id) {
  const res = await axios.get(`/api/v1/users/${id}/notifications`)
  return res.data
}

export async function getTeacherNotifications(id) {
  const res = await axios.get(`/api/v1/teachers/${id}/notifications`)
  return res.data
}

export async function startLessonWithZoom(id, url) {
  const res = await axios.post(`/api/v1/live_lessons/${id}/start_with_zoom`, url)
  return res.data
}

export async function startLessonWithBBB(id) {
  const res = await axios.post(`/api/v1/live_lessons/${id}/start_with_bbb`, {})
  return res.status
}

export async function getExamResults(userId) {
  const res = await axios.get(`/api/v1/exam/users/${userId}/exam_details`)
  return res.data
}

export async function getExamDetails() {
  const res = await axios.get('/api/v1/exams')
  return res.data
}

export async function sendExamResults(data) {
  const res = await axios.post('/api/v1/exam/result', data)
  return res
}

export async function updateUserData(userId, data) {
  const res = await axios.patch(`/api/v1/users/${userId}`, { user: data })
  return res
}

export async function updateUserPass(userId, data) {
  const res = await axios.patch(`/api/v1/users/${userId}/update_password`, { user: data })
  return res
}

export async function updateTeacherData(teacherId, data) {
  const res = await axios.patch(`/api/v1/teachers/${teacherId}`, { teacher: data })
  return res
}

export async function updateTeacherPass(teacherId, data) {
  const res = await axios.patch(`/api/v1/teachers/${teacherId}/update_password`, { teacher: data })
  return res
}

export async function getUserPosibleCourses(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/language_courses`)
  return res.data
}

export async function getUserPosibleLessonContents(courseId) {
  const res = await axios.get(`/api/v1/courses/${courseId}/lesson_contents`)
  return res.data
}

export async function getRecomendedContent(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/next_content`)
  return res.data
}

export async function sendLessonEstimate(lessonId, data) {
  const res = await axios.post(`/api/v1/live_lessons/${lessonId}/estimate_lesson`, data)
  return res.status
}

export async function sendUserLessonEstimate(lessonId, data) {
  const res = await axios.post(`/api/v1/live_lessons/${lessonId}/user_estimate_lesson`, data)
  return res.status
}

export async function updateLearningProcess(userId, data) {
  const res = await axios.patch(`/api/v1/users/${userId}`, data)
  return res
}

export async function getGoals() {
  const res = await axios.get('/api/v1/goals')
  return res.data
}

export async function sendMaterialsToLesson(id, materails) {
  const data = {
    book_material_ids: materails,
  }
  const res = await axios.post(`/api/v1/live_lessons/${id}/add_book_materials`, data)
  return res.status
}

export async function sendFileToLesson(id, file) {
  const formData = new FormData()
  formData.append('additional_materials', file, file.name)
  const res = await axios.post(`/api/v1/live_lessons/${id}/add_additional_materials`, formData)
  return res
}

export async function createNewError(errorData) {
  const res = await axios.post('/api/v1/error_notifications', errorData)
  return res.status
}

export async function previousTeacherLessons(page) {
  let pageNum = ''
  if (page) pageNum = `?page=${page}`
  const res = await axios.get(`/api/v1/teacher_profile/finished_lessons${pageNum}`)
  return res.data
}

export async function workspaceUsersCRM(categories, page) {
  const res = await axios.get('/api/v1/adminpanel/users/crm', {
    params: {
      'statuses[]': categories,
      page,
    },
  })

  return res.data
}

export async function workspaceUsers(searchWord = false) {
  let queryParams = ''

  if (searchWord) queryParams = `?search_term=${searchWord}&page=1`

  const res = await axios.get(`/api/v1/adminpanel/users${queryParams}`)

  return res.data
}

export async function adminpanelUserData(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}`)
  return res.data
}

export async function getUserAvailablePackets(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/available_bought_packages`)
  return res.data
}

export async function getUserConfirmedLesson(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/confirmed_live_lessons`)
  return res.data
}

export async function getUserComments(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/admin_comments`)
  return res.data
}

export async function getUserEmailHistory(id, page) {
  let pageNum = ''
  if (page) pageNum = `?page=${page}`
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/email_notifications${pageNum}`)
  return res.data
}

export async function getUserBoughtHistory(id, page) {
  let pageNum = ''
  if (page) pageNum = `?page=${page}`
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/bought_packages_history${pageNum}`)
  return res.data
}

export async function getAllUserBoughtHistory(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/bought_packages_all`)

  return res.data
}

export async function getUserTeachers(id) {
  const res = await axios.get(`/api/v1/adminpanel/users/${id}/teachers`)
  return res.data
}

export async function updateAdminComment(commentId, comment) {
  const res = await axios.patch(`/api/v1/adminpanel/user_admin_comments/${commentId}`, { user_admin_comment: { body: comment } })
  return res.data
}

export async function newAdminComment(userId, comment) {
  const res = await axios.post('/api/v1/adminpanel/user_admin_comments', {
    user_admin_comment: {
      user_id: userId,
      body: comment,
    },
  })
  return res.data
}

export async function deleteAdminComment(commentId) {
  const res = await axios.delete(`/api/v1/adminpanel/user_admin_comments/${commentId}`)
  return res.data
}

export async function workspaceUsersByPage(searchWord, page) {
  const res = await axios.get(`/api/v1/adminpanel/users?search_term=${searchWord}&page=${page}`)
  return res.data
}

export async function updateWorkspaceUsersByStatus(status, page) {
  const res = await axios.get(`/api/v1/adminpanel/users/status?status=${status}&page=${page}`)
  return res.data
}

export async function updateUserStatus(userId, status) {
  const res = await axios.patch(`/api/v1/adminpanel/users/${userId}/update_status`, {
    user_status: { status },
    create_admin_status: true,
  })
  return res
}

export async function updateUserStatusDate(userId, date) {
  const res = await axios.patch(`/api/v1/adminpanel/users/${userId}/update_status`, { user_status: { process_later_date: date } })
  return res.status
}

export async function updateUserStatusGroupDate(userId, date) {
  const res = await axios.patch(`/api/v1/adminpanel/users/${userId}/update_status`, { user_status: { interested_in_date: date } })
  return res.status
}

export async function setExpectedAmount(userId, sum) {
  const res = await axios.patch(
    `/api/v1/adminpanel/admin_user_statuses/${userId}`,
    { admin_user_status: { expected_amount: sum } },
  )
  return res
}

export async function setTrialLesson(userId, languageId, trialDate) {
  const res = await axios.patch(
    `/api/v1/adminpanel/users/${userId}/assign_trial_lesson`,
    {
      language_id: languageId,
      lesson_date: trialDate,
    },
  )
  return res.status
}

export async function getUnitExerciseList(unitId, lessonId) {
  const res = await axios.get(`/api/v1/lesson_contents/${unitId}/live_lessons/${lessonId}/exercises`)
  return res.data
}

export async function getFullExerciseDescription(id) {
  const res = await axios.get(`/api/v1/exercises/${id}`)
  return res.data
}

export async function sendHomeworkId(liveLessonId, arr) {
  const paramsData = { added_homeworks: arr }
  // if (isHomework) paramsData.homework = true
  const res = await axios.post(`/api/v1/live_lessons/${liveLessonId}/add_homework_unit`, paramsData)
  return res.status
}

export async function sendExercisesId(liveLessonId, arr, isHomework) {
  const paramsData = { exercise_ids: arr }
  if (isHomework) paramsData.homework = true
  const res = await axios.post(`/api/v1/live_lessons/${liveLessonId}/add_exercises`, paramsData)
  return res.status
}

export async function exerciseConstructorContents(id, isHomework) {
  let query = ''
  if (isHomework) query = '?homework=true'
  const res = await axios.get(`/api/v1/live_lessons/${id}/exercise_constructor_contents${query}`)
  return res.data
}

export async function getLiveLessonAddedExercises(id, isHomework) {
  let query = ''
  if (isHomework) query = '?homework=true'
  const res = await axios.get(`/api/v1/live_lessons/${id}/added_exercises${query}`)
  return res.data
}

export async function getLiveLessonHomeworkAddedExercises(id) {
  const res = await axios.get(`/api/v1/live_lessons/${id}/added_homework_exercises`)
  return res.data
}

export async function deleteHomework(lessonId, homeworkId) {
  const res = await axios.delete(`/api/v1/live_lessons/${lessonId}/delete_homework_unit/?homework_unit_id=${homeworkId}`)
  return res.status
}

export async function LessonLeft(userId) {
  const res = await axios.get(`/api/v1/users/${userId}/lessons_left`)
  return res.data
}

export async function setRefusedReson(userId, reason) {
  const res = await axios.patch(`/api/v1/adminpanel/users/${userId}/update_status`, { user_status: { refusal_reason: reason } })
  return res.status
}

export async function getUtmMarks(from, to, page) {
  let queryParams = ''
  if (from && to && page) {
    queryParams = `?from=${from}&to=${to}&page=${page}`
  }
  const res = await axios.get(`/api/v1/adminpanel/utm_mark_groups/statistic${queryParams}`)
  return res.data
}

export async function getAdmStatisticData(data = false) {
  let queryParams = ''
  if (data) queryParams = `?initial_date=${data}`
  const result = await axios.all([
    axios.get(`/api/v1/adminpanel/statistic/price_statistic${queryParams}`),
    axios.get(`/api/v1/adminpanel/statistic/users_statistic${queryParams}`),
    axios.get(`/api/v1/adminpanel/statistic/purchase_month_statistic${queryParams}`),
  ])
    .then(res => {
      const data = []
      res.forEach(el => data.push(el.data))
      return data
    })
  return result
}

export async function getUsersPackagesStatistic() {
  const res = await axios.get('/api/v1/adminpanel/statistic/users_packages')
  return res.data
}

export async function getPaymentsStatistic() {
  const res = await axios.get('/api/v1/adminpanel/statistic/completed_payments')
  return res.data
}

export async function getLessonsStatistic() {
  const res = await axios.get('/api/v1/adminpanel/statistic/live_lessons')
  return res.data
}

export async function getPurchaseWeeklyStatistic() {
  const res = await axios.get('/api/v1/adminpanel/statistic/diagram_week_statistic')
  return res.data
}

export async function getPurchaseMonthStatistic() {
  const res = await axios.get('/api/v1/adminpanel/statistic/diagram_month_statistic')
  return res.data
}

export async function getTeachersList(searchWord = false) {
  let queryParams = ''
  if (searchWord) queryParams = `?search_term=${searchWord}`
  const res = await axios.get(`/api/v1/adminpanel/teachers${queryParams}`)
  return res.data
}

export async function blockTheTeacher(teacherId) {
  const res = await axios.post(`/api/v1/adminpanel/teachers/${teacherId}/to_ban`)
  return res.data
}

export async function unblockTheTeacher(teacherId) {
  const res = await axios.post(`/api/v1/adminpanel/teachers/${teacherId}/ban_rollback`)
  return res.data
}

export async function deleteTheTeacher(teacherId) {
  const res = await axios.post(`/api/v1/adminpanel/teachers/${teacherId}/to_delete`)
  return res.status
}

export async function deletedTeacherRollback(teacherId) {
  const res = await axios.post(`/api/v1/adminpanel/teachers/${teacherId}/deleted_rollback`)
  return res.data
}

export async function inviteTeacher(email) {
  const res = await axios.post('/api/v1/adminpanel/teachers/send_invitation', { email })
  return res.data
}

export async function deleteTeacher(teacherId) {
  const res = await axios.delete(`/api/v1/adminpanel/teachers/${teacherId}`)
  return res.status
}

export async function fullPaymentStatistic(page) {
  let pageNum = ''
  if (page) pageNum = `?page=${page}`
  const res = await axios.get(`/api/v1/adminpanel/bought_packages/bought_packages_history${pageNum}`)
  return res.data
}

export async function managersProductivity(data = false) {
  let queryParams = ''
  if (data) queryParams = `?initial_date=${data}`
  const res = await axios.get(`/api/v1/adminpanel/statistic/managers${queryParams}`)
  return res.data
}

export async function getTeachersStatistic(searchWord = false) {
  let queryParams = ''
  if (searchWord) queryParams = `?search_term=${searchWord}`
  const res = await axios.get(`/api/v1/adminpanel/teachers/lessons_statistic${queryParams}`)
  return res.data
}

export async function getCalendarData(id) {
  const res = await axios.get(`/api/v1/adminpanel/teachers/${id}/lessons_calendar_statistic`)
  return res.data
}

export async function getUserLessonsStatistic(id, from, to) {
  let range = ''
  if (from) range = `?date_from=${from}&date_to=${to}`
  const res = await axios.get(`/api/v1/adminpanel/teachers/${id}/user_lessons_statistic${range}`)
  return res.data
}

export async function getLanguagesAndCourses() {
  const res = await axios.get('/api/v1/adminpanel/users/languages')
  return res.data
}

export async function updateUserLangData(userId, lang, lvl, rec = null, exam) {
  const res = await axios.patch(
    `/api/v1/adminpanel/users/${userId}`,
    {
      user: {
        desirable_language: lang,
        english_knowledge: lvl,
        recommended_course: rec,
        scheduled_exam: exam,
      },
    },
  )
  return res
}

export async function udateUserConnectionType(userId, data) {
  const res = await axios.patch(
    `/api/v1/adminpanel/users/${userId}`,
    {
      user: {
        connection_type: data,
      },
    },
  )
  return res
}

export async function markUserInGroupLesson(userId, lessonId) {
  const res = await axios.post(
    `/api/v1/users/${userId}/start_group_lesson`,
    { live_lesson_id: lessonId },
  )
  return res.status
}

export async function getStudentsCamps(search_term, aasm_state) {
  if (!search_term) search_term = null
  const res = await axios.get('/api/v1/adminpanel/students_camps', {
    params: {
      search_term,
      aasm_state,
    },
  })
  return res.data
}

export async function getCampStudents(id, searchWord) {
  let queryParams = ''
  if (searchWord) {
    queryParams = `?search_term=${searchWord}`
  }
  const res = await axios.get(`/api/v1/adminpanel/students_camps/${id}${queryParams}`)
  return res.data
}

export async function getAllTeachers() {
  const res = await axios.get('/api/v1/adminpanel/teachers')
  return res.data
}

export async function getGroupCourses() {
  const res = await axios.get('/api/v1/adminpanel/courses/for_groups')
  return res.data
}

export async function createGroup(data) {
  const res = await axios.post(
    '/api/v1/adminpanel/students_camps',
    data,
  )
  return res.data
}

export async function updateGroupData(groupId, data) {
  const res = await axios.patch(
    `/api/v1/adminpanel/students_camps/${groupId}`,
    data,
  )
  return res.data
}

export async function updateStudyingStatus(campId, command) {
  const res = await axios.post(`/api/v1/adminpanel/students_camps/${campId}/${command}`)

  return res.status
}

export async function removeGroup(groupId) {
  const res = await axios.delete(
    `/api/v1/adminpanel/students_camps/${groupId}`,
  )
  return res.status
}

export async function createNewHomework(lessonId) {
  const res = await axios.post(`/api/v1/live_lessons/${lessonId}/create_homework_unit`)
  return res
}

export async function addUserToCamp(groupId, data) {
  const res = await axios.post(
    `/api/v1/adminpanel/students_camps/${groupId}/add_user`,
    data,
  )
  return res.data
}

export async function confirmCamp(groupId) {
  const res = await axios.post(`/api/v1/adminpanel/students_camps/${groupId}/confirm`)
  return res.status
}

export async function removeUserFromCamp(groupId, userId) {
  const res = await axios.post(
    `/api/v1/adminpanel/students_camps/${groupId}/remove_user`,
    { user_id: userId },
  )
  return res.status
}

export async function getUserByIdOrEmail(searchWord) {
  const queryParams = `?search_term=${searchWord}`
  const res = await axios.get(`/api/v1/adminpanel/users/find_by_id_or_email${queryParams}`)
  return res.data
}

export async function getUserStudentsCamps(userId) {
  const res = await axios.get(`/api/v1/adminpanel/users/${userId}/students_camps`)
  return res.data
}

export async function createNewUnitContent(course, data) {
  const res = await axios.post(
    '/api/v1/adminpanel/lesson_contents',
    {
      course_id: course,
      lesson_content: data,
    },
  )
  return res
}

export async function updateUnitContent(id, data) {
  const res = await axios.patch(
    `/api/v1/adminpanel/lesson_contents/${id}`,
    {
      lesson_content: data,
    },
  )
  return res
}

export async function uploadLessonFiles(files) {
  const formData = new FormData()
  for (const key of Object.keys(files)) {
    formData.append(`${key}`, files[key])
  }
  const res = await axios.post(
    '/api/v1/adminpanel/material_uploads',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  )
  return res.data
}

export async function getEditLessonContent(lessonContentId) {
  const res = await axios.get(`/api/v1/adminpanel/lesson_contents/${lessonContentId}/edit`)
  return res.data
}

export async function removeUnitExercise(exerciseId) {
  const res = await axios.delete(
    `/api/v1/adminpanel/exercises/${exerciseId}`,
  )
  return res.status
}

export async function removeExerciseTask(exerciseId, taskId) {
  const res = await axios.delete(
    ` /api/v1/adminpanel/exercises/${exerciseId}/destroy_task/${taskId}`,
  )
  return res.status
}

export async function removeTaskAnswer(answerId) {
  const res = await axios.delete(
    `/api/v1/adminpanel/possible_answers/${answerId}`,
  )
  return res.status
}

export async function removeFiles(fileId) {
  const res = await axios.delete(
    `/api/v1/adminpanel/material_uploads/${fileId}`,
  )
  return res.status
}

export async function userRegistration(data) {
  const res = await axios.post(
    '/users',
    { user: data },
  )
  return res
}

export async function currentUserInfo() {
  const res = await axios.get('/api/v1/users/current_user_info')
  return res.data
}

export async function userSignIn(data) {
  const res = await axios.post(
    '/users/sign_in',
    { user: data },
  )
  if (res?.status === 200) {
    Storage.set('auth_token', res?.headers.authorization) // check Status
    return res
  }
  return res
}

export async function teacherSignIn(data) {
  const res = await axios.post(
    '/teachers/sign_in',
    { teacher: data },
  )
  if (res?.status === 200) {
    Storage.set('auth_token', res?.headers.authorization) // check Status
    return res
  }
  return res
}

export async function getLandingData() {
  const res = await axios.get('/api/v1/landing')
  return res.data
}

export async function getArticles() {
  const res = await axios.get('/api/v1/articles')
  return res.data.articles
}

export async function getArticleBySlug(slug) {
  const res = await axios.get(`/api/v1/articles/${slug}`)
  return res.data
}

export async function getAdmArticleByID(id) {
  const res = await axios.get(`/api/v1/adminpanel/articles/${id}`)
  return res.data
}

export async function updateArticleData(id, data, { onUploadProgress }) {
  const res = await axios.put(
    `/api/v1/adminpanel/articles/${id}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total),
          10)
        onUploadProgress(progress)
      },
    },
  )
  return res
}

export async function createArticle(data) {
  const res = await axios.post(
    '/api/v1/adminpanel/articles',
    data,
  )
  return res
}

export async function deleteArticle(id) {
  const res = await axios.delete(
    `/api/v1/adminpanel/articles/${id}`,
  )
  return res
}

export async function getIntensivesPack() {
  const res = await axios.get('/api/v1/landing/intensive_packages')
  return res.data.intensive_packages.intensiv
}

export async function getTeachers() {
  const res = await axios.get('/api/v1/teachers/teachers_presentation')
  return res.data
}

export async function getLandingCourseByID(id) {
  const res = await axios.get(`/api/v1/courses/${id}/landing`)
  return res.data
}

export async function getTeamMembers(id) {
  const res = await axios.get(`/api/v1/languages/${id}/team_members`)
  return res.data
}

export async function getLanguageInfo(id) {
  const res = await axios.get(`/api/v1/languages/${id}/landing`)
  return res.data
}

export async function getGroupCoursesPack(id) {
  const res = await axios.get(`/api/v1/languages/${id}/courses_for_group`)
  return res.data
}

export async function teacherSignInDemo(data) {
  const res = await axios.post(
    '/teachers/sign_in',
    { teacher_id: data },
  )
  Storage.set('auth_token', res.headers.authorization)
  return res
}

export async function resetUserPassword(data) {
  const res = await axios.post(
    '/users/password',
    { user: { ...data } },
  )
  return res
}

export async function resetPassword(data) {
  const res = await axios.put(
    '/users/password',
    { user: { ...data } },
  )
  return res
}

export async function getExamsList() {
  const res = await axios.get('/api/v1/adminpanel/exams')
  return res.data
}

export async function getTeacherList() {
  const res = await axios.get('/api/v1/adminpanel/teachers')

  return res.data
}

export async function getCommentList(user_id, teacher_id, user_mark, current_page) {
  const res = await axios.get('/api/v1/adminpanel/user_estimations/user_comments', {
    params: {
      user_id,
      teacher_id,
      user_mark,
      current_page,
    },
  })

  return res.data
}

export async function sendExerciseReport(data) {
  const res = await axios.post('/api/v1/exercise_reports', data)

  return res.status
}

export async function getReportList(accountable_type, workable_type, aasm_state, current_page) {
  const res = await axios.get('/api/v1/adminpanel/exercise_reports', {
    params: {
      accountable_type,
      workable_type,
      aasm_state,
      current_page,
    },
  })

  return res.data
}

export async function updateReportProcessingStatus(reportId, newProcessingStatus) {
  const res = await axios.patch(`/api/v1/adminpanel/exercise_reports/${reportId}`, { aasm_state: newProcessingStatus })

  return res.status
}

export async function getCoursePackages(courseId) {
  const res = await axios.get(`/api/v1/adminpanel/courses/${courseId}`)

  return res.data
}

export async function addBoughtPackage(
  package_id,
  user_id,
) {
  const res = await axios.post('/api/v1/adminpanel/bought_packages', {
    bought_package: {
      package_id,
      user_id,
    },
  })

  return res
}

export async function changeBoughtPackage(
  package_id,
  user_id,
  old_bought_package_id,
  transferred_lessons,
  overpay,
) {
  const res = await axios.post('/api/v1/adminpanel/bought_packages/transfer_package', {
    package_id,
    user_id,
    transferred_purchase: {
      old_bought_package_id,
      transferred_lessons,
      overpay,
    },
  })

  return res
}

export async function deleteBoughtPackage(boughtPackageId) {
  const res = await axios.delete(`/api/v1/adminpanel/bought_packages/${boughtPackageId}`)

  return res.status
}

export async function freezeBoughtPackage(bought_package_id, debited_amount) {
  const res = await axios.patch('/api/v1/adminpanel/bought_packages/freeze_b_p', {
    bought_package_id,
    debited_amount,
  })

  return res.status
}

export async function getArticleList(theme, current_page) {
  const res = await axios.get('/api/v1/articles/paginated_articles', {
    params: {
      theme,
      current_page,
    },
  })

  return res.data
}