import React, { useContext, useEffect } from 'react'
import { PersonContext } from '@/contexts'
import { useLocation } from 'react-router-dom'
import useModal from '@/hooks/useModal'
import Button from '@libs/Buttons/Button'
import x from '@images/SVG/x'
import LanguageChooser from '@landing_c/General/LanguageChooser'
import Links from './Links'
import Logined from './logined'
import classes from './classes.module.scss'

export default ({ onClose }) => {
  console.log(window.innerWidth)
  const { person } = useContext(PersonContext)
  const { pathname } = useLocation()
  const { changeSettings } = useModal()
  const onClickHandler = (e) => {
    const { type } = e.target.dataset
    changeSettings(true, { type })
  }

  useEffect(() => {
    const asideWrapper = document.getElementsByClassName(classes.aside_wrapper)[0]
    console.log(asideWrapper)

    if (pathname === '/' && window.innerWidth <= 576) {
      asideWrapper.style.paddingBottom = '75px'
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <aside className={classes.aside}>
        <header>
          {
            person?.user.role
            && person.user.role !== 'admin'
            && <figure className={classes.avatar}>
              <img src={person.user.avatar_url} alt="avatar" />
            </figure>
          }
          <img src={x} alt="x" onClick={onClose} />
        </header>
        <div className={classes.aside_wrapper}>
          {
            person?.user.role && <Logined role={person.user.role} />
          }
          <main>
            <Links />
            {
              (!person?.user.role || (person.user.role === 'admin'))
              && <div className={classes.button_group}>
                {
                  (pathname === '/' && window.innerWidth <= 576)
                    ? null
                    : <Button
                        color="purple"
                        source="landing"
                        onClick={onClickHandler}
                        data-type="registration"
                    >
                      Спробувати урок
                    </Button>
                }
                <Button
                  color="purple_transparent_invert"
                  source="landing"
                  onClick={onClickHandler}
                  data-type="log_in_user"
                >
                  Увійти
                </Button>
              </div>
            }
          </main>
          <footer>
            <p><a href="mailto:yolostudyonline@gmail.com">yolostudyonline@gmail.com</a></p>
            <p><a href="tel:+380955838283">+38 095 583 82 83</a></p>
            <LanguageChooser />
          </footer>
        </div>
      </aside>
    </div>
  )
}